.form-field__file-upload {
  height: 14rem;
  max-width: 100%;
  text-align: center;
  position: relative;

  &__input {
    display: none;
  }

  &__label {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 0.5rem 0.5rem 0 0;
    border-style: dashed;
    border-color: $color-telus-greyCloud;
    border-bottom: 0;
    padding: 4rem;
    cursor: pointer;

    &.error {
      border-color: $color-telus-red;
    }

    &__upload-button {
      background-color: transparent;
      border: 0;
      font-size: inherit;
      color: inherit;
      margin: 0 0.5rem;
    }

    &__text {
      opacity: 0.8;
    }

    &__accepted-type {
      font-size: 12px;
      opacity: 0.6;
    }
  }

  &__drag {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &__file-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: -1rem;
    border-width: 2px;
    border-radius: 0 0 0.5rem 0.5rem;
    border-style: dashed;
    border-color: $color-telus-greyCloud;
    border-top: 0;
    padding: 0 2rem 2rem 2rem;

    &.error {
      border-color: $color-telus-red;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: $color-telus-greyThunder;

      &__filename {
        font-size: 0.875rem;
        color: var(--color-telus-greyThunder);
      }

      &__delete {
        background-color: transparent;
        border: 0;
        cursor: pointer;
      }

      &__error {
        margin: 0.5rem 0 0 2.2rem;

        &__item {
          font-size: 0.8125rem;
          color: $color-telus-redDark;
        }
      }
    }
  }
}
