.alert {
  background-color: $color-telus-redLight;
  border-color: $color-telus-redLight;
  border-width: 1px;
  border-radius: 4px;
  padding: 1.5rem 1rem;
  color: $color-telus-red;
  font-weight: 400;
  line-height: 1.5px;
}
