.form-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.form-field--group--main {
  border: 1px solid $color-telus-purpleTelus;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  z-index: 5;
}

.form-card {
  width: 315px;
  background-color: rgb(255, 255, 255);
  border: 1px solid $color-telus-purpleTelus;;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  transition: all 0.2s ease-in;
}

.form-card:hover {
  box-shadow: inset 0 0 0 1.5px $color-telus-purpleTelus;;
}

.form-card__inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  height: 100%;
}
