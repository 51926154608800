*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'telusweb', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a:link {
  text-decoration: none;
}

main {
  flex-grow: 1;
}
