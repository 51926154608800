.form-field--address {
  padding: 2rem 1rem 3rem 1rem;
  &.error {
    border: 1px solid $color-telus-redDark;
  }
}

.pac-icon {
  display: none;
}

.pac-logo::after {
  content: '';
  background-image: none !important;
  display: none;
}

.pac-item {
  border: 0;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: $color-telus-greyAthens;
    cursor: pointer;

    & span {
      color: $color-telus-purpleTelus;
    }
  }

  & span {
    color: #2c2e30;
    font-size: 1rem;
    line-height: 24px;
    font-family: HelveticaNow700normal;
  }
}

.pac-container {
  max-width: 565px;
  margin-top: 0.125rem;
  margin-left: 0.5rem;
  padding-top: 0.5rem;
  border: 1px solid var(--color-telus-greyMystic);
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
}
