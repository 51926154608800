.container {
  max-width: 87rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.notification-button-wrapper {
  border: 0;
  cursor: pointer;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.form-field--error-message {
  background-color: rgb(255, 246, 248);
  border-color: rgb(255, 246, 248);
  border-width: 1px;
  border-radius: 4px;
  padding: 12px 16px;
  color: rgb(225, 35, 57);
  font-weight: 400;
  line-height: 1.5px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.gap-small {
  gap: 0.5rem;
}
