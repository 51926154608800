.header {
  box-shadow: 0 0.1rem 0.8rem rgb(0 0 0 / 20%);

  &__nav-bar {
    background-color: $color-telus-purpleTelus;
    padding: 1rem 0;

    &__list {
      display: flex;
      justify-content: flex-end;

      &-item {
        border-right: 1px solid $color-telus-white;
        padding: 0 1.5rem;

        &:last-child {
          border-right: 0;
          padding-right: 0;
        }

        & a {
          color: $color-telus-white;
        }

        & button {
          background-color: transparent;
          border: 0;
          color: $color-telus-white;
          font-size: 1rem;
          cursor: pointer;
        }
      }
    }
  }

  &__sub-section {
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
      width: 12.5rem;
      margin-top: 1rem;
    }
  }
}
